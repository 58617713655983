import { configureStore } from '@reduxjs/toolkit';
import cartReducer from "@/app/slices/cartSlice"
// Import your slice reducer

const store = configureStore({

    reducer: {
        cart: cartReducer,
    },
});

export default store;