import {memo} from "react";

const DropDownIcon = ({onClick}) => {
    return (
        <div onClick={onClick}>
            <svg width="12" height="7" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 0.999999L7 8L13.5 1" stroke="#5B4841" strokeLinecap="round"/>
            </svg>
        </div>
    )
}
export default memo(DropDownIcon)