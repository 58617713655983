'use client';
import React, { useEffect, useRef, useState } from "react";
import LoaderContext from "@/app/context/LoaderContext";

import styles from "@/app/styles/components/loader-wrapper.module.scss";

const ignoredCalls = []

const isCallIgnored = (call) =>{
    if(call.method === 'get'){
        return true;
    }

    const ignoredCall = ignoredCalls.find(ignored => ignored.method === call.method && call.url.startsWith(ignored.url));
    return Boolean(ignoredCall);
}

const LoaderWrapper = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [manualLoading, setManualLoading] = useState(false);
    const countRef = useRef(0)

    const value = {
        loading: manualLoading,
        setLoading: setManualLoading
    };

    useEffect(() => {
        if (countRef.current <= 0 ||!loading) {
            countRef.current = 0;
            setLoading(false);
        }
    }, [countRef.current])


    return <LoaderContext.Provider value={value}>
        {(loading || manualLoading) &&
            <div className={styles.loaderContainer}>
                <div className={styles.circularLoader}>
                    <img src="/header-logo.svg"/>
                </div>
            </div>
        }
        {children}
    </LoaderContext.Provider>
}


export default LoaderWrapper;