'use client'
import React, { useState } from 'react';
import CartModalContext from '/src/app/context/CartModalContext.js';


export const CartModalProvider = ({ children }) => {
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const [reload, setReload] = useState(false);

    return (
        <CartModalContext.Provider value={{ isCartModalOpen, setIsCartModalOpen, reload, setReload }}>
            {children}
        </CartModalContext.Provider>
    );
}

