import {memo} from "react";

const UpIcon = ({onClick}) => {
    return (
        <div onClick={onClick}>
            <svg width="12" height="7" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 9L8 2L1.5 9" stroke="#5B4841" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </div>
    )
}
export default memo(UpIcon)