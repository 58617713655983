export const subscribeUser = async (email) => {
    const response = await fetch('/api/newsletter', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });

    if (response.ok) {
        return true;
    } else {
        const error = await response.json();
        console.error('Error response from API:', error);
        return false;
    }
};